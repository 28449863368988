import * as React from 'react';

interface CloseIconProps {
  onClick();
}

export const CloseButton: React.FC<CloseIconProps> = props => (
  <button
    className="block ml-auto right-0 text-white text-4xl px-6 py-2 m-2"
    onClick={props.onClick}
  >
    ×
  </button>
);
