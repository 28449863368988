import * as React from 'react';
import * as ReactModal from 'react-modal';
import { CloseButton } from './CloseButton';

const noop = () => {
};

export { CloseButton } from './CloseButton';

export type ModalProps = {
  dialog?: boolean;
  notAttachedToTop?: boolean;
  fullscreen?: boolean;
  className?: string;
  overlayClassName?: string;

  onAfterOpen?();

  onRequestClose();

  isOpen: boolean;
};

ReactModal.setAppElement('body');

export const Modal: React.FC<ModalProps> = ({ children, isOpen, onAfterOpen, onRequestClose }) =>
  (
    <ReactModal
      closeTimeoutMS={500}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen ? onAfterOpen : noop}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      {children}
    </ReactModal>
  );
